import { storefrontId as productId, stripeProductId } from '../assets/products';
export function storefrontIdToStripeId(storefrontId: string): string | null | undefined {
  switch (storefrontId) {
    case productId.toletta:
      return stripeProductId.toletta;
    case productId.tolettaBaseToletta:
      return stripeProductId.tolettaBaseToletta;
    case productId.catBaseToletta:
      return stripeProductId.catBaseToletta;
    default:
      return null;
  }
}

export const errMsgConverter = (errMsg: string) => {
  switch (errMsg) {
    case 'couponCode is invalid':
      return 'クーポンコードが有効ではありません。入力値をご確認ください。';
    case 'user is already exists, but password is not correct':
      return '会員登録に失敗しました。既にアカウントをお持ちの場合はページトップ右上のアイコンからログインをしてご注文ください。';
    case 'customer is not found':
      return '顧客情報が取得できませんでした。大変お手数ですがお問い合わせください。';
    case 'the charge permission is not chargeable':
      return '定期支払いの請求ができないためトレッタの追加購入ができません。再度注文が必要です。';
    case 'Phone has already been taken':
      return '電話番号が既に登録されているため新規でのご注文に失敗しました。';
    case 'Session declined due to payment decline, MFA decline or fraud decline':
      return 'カードの支払いが拒否されたため注文が完了しませんでした。カードをご確認の上、大変お手数ですが再度ご注文をお願いいたします。';
    case 'カード情報の更新に失敗しました':
      return 'エラーが発生しました。しばらくしてからもう一度お試しください。';
    default:
      return '不明なエラーが発生しました。大変お手数ですがお問い合わせください。';
  }
};
