import { CheckoutData, CheckoutItem, LineItem, OrderData, QueriedProduct } from '../types';

export function toRest(storefrontId?: string, isNeedDecode: boolean = true) {
  if (typeof window === `undefined` || !storefrontId) return 0;
  const adminGraphqlId = isNeedDecode ? window.atob(storefrontId) : storefrontId;
  return Number(adminGraphqlId.split('/').pop());
}

export function shopifyIdExport(shopifyId: string) {
  return Number(shopifyId.split('/').pop());
}

// variants以下のstorefrontId
export const storefrontId =
  {
    // スタンダードプラントレッタ
    toletta: process.env.GATSBY_STOREFRONT_ID_STANDARD_PLAN,
    // 台数ベーストレッタ
    tolettaBaseToletta: process.env.GATSBY_STOREFRONT_ID_TOLETTA_PLAN,
    // 頭数ベーストレッタ
    catBaseToletta: process.env.GATSBY_STOREFRONT_ID_CAT_BASE,
  };

export const restVariantId =
  {
    // スタンダードプラントレッタ
    toletta: toRest(process.env.GATSBY_REST_VARIANT_ID_STANDARD_PLAN),
    // 台数ベーストレッタ
    tolettaBaseToletta: toRest(process.env.GATSBY_REST_VARIANT_ID_TOLETTA_PLAN),
    // 頭数ベーストレッタ
    catBaseToletta: toRest(process.env.GATSBY_REST_VARIANT_ID_CAT_BASE),
  };

export const stripeProductId =
  {
    // スタンダードプラントレッタ
    toletta: process.env.GATSBY_STRIPE_PRODUCT_ID_STANDARD_PLAN,
    // 台数ベーストレッタ
    tolettaBaseToletta: process.env.GATSBY_STRIPE_PRODUCT_ID_TOLETTA_PLAN,
    // 頭数ベーストレッタ
    catBaseToletta: process.env.GATSBY_STRIPE_PRODUCT_ID_CAT_BASE,
  };

// 最大注文数量
export const maxOrderQuantity = 10;

/**
 * 製品の金額を返却します
 * @param {QueriedProduct} product 
 * @return {Number} 製品の金額
 */
export function getProductPrice(product: QueriedProduct) {
  // Shopifyから返却されたVariant情報の金額を返却する
  return Number(product.priceRangeV2.minVariantPrice.amount);
}

/**
 * lineitemの金額を返却します
 * @param {LineItem | CheckoutItem} item 
 * @return {Number} lineitemの金額
 */
export function getLineItemPrice(item: LineItem | CheckoutItem) {
  // Shopifyから取得した注文情報の金額を返却する
  return Number(item.price);
}

/**
 * 小計を返却します
 * @param {OrderData | CheckoutData} order 
 * @return {Number} 小計
 */
export function getTotalLineItemsPrice(order: OrderData | CheckoutData) {
  // Shopifyから取得した注文情報の小計を返却する
  return Number(order.total_line_items_price);
}

/**
 * 税を返却します
 * @param {OrderData | CheckoutData} order 
 * @return {Number} 税
 */
export function getTotalTax(order: OrderData | CheckoutData) {
  // Shopifyから取得した注文情報の税を返却する
  return Number(order.total_tax);
}

/**
 * 合計を返却します
 * @param {OrderData | CheckoutData} order 
 * @return {Number} 合計
 */
export function getTotalPrice(order: OrderData | CheckoutData) {
  // Shopifyから取得した注文情報の合計を返却する
  return Number(order.total_price);
}
