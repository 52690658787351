import { CartItem } from '../types';

export function calcTotalTax(cart: CartItem[]) {
  let tax = 0;
  cart.forEach(i => {
    tax += Number(i.price) * 0.1 * i.quantity;
  });
  return Math.round(tax);
}

export function calcLineItemsPrice(cart: CartItem[]) {
  let sum = 0;
  cart.forEach(i => {
    sum += Number(i.price) * i.quantity;
  });
  return sum;
}

export function calcShippingPrice(_: CartItem[]) {
  // 本体販売のみのため送料は0円固定
  return 0;
}
