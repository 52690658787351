import * as Sentry from '@sentry/gatsby';
import {
  changePassword,
  getUser,
  getUserPlan,
  getUserToken,
  isSubscribeUser,
  login,
  resetPassword,
  setUserPlan,
  setUserToken,
  updateUser,
} from '../repository/tolettaApi';
import {
  cancelOrder,
  changeAcceptsMarketing,
  createCustomer,
  createOrder,
  getCardInfo,
  getCustomer,
  getHashPassword,
  getInventoryQuantity,
  getOrderHistory,
  getPlan,
  getSubscriptionStatus,
  isCancellable,
  updateCustomer,
  changeCC,
} from '../repository/tolettaWebApi';
import { PLANS } from '../assets/plans';
import {
  CartItem,
  LoginData,
  OrderData,
  ShowCCData,
  UserData,
  RegisterInputData,
  DraftOrderInputData,
  CustomerData,
} from '../types';
import { restVariantId, storefrontId } from '../assets/products';
import { calcShippingPrice, calcTotalTax } from '../utils/calc';
import { storefrontIdToStripeId } from '../utils/converter';
import {
  completeDraftOrder,
  createDraftOrder,
  deleteCustomer,
  getCouponStatus,
  register,
  registerCoupon,
  remindMe,
} from '../repository/tolettaShopifyApi';
import { provinceCode } from '../assets/province_codes';

export async function loginService(loginData: LoginData) : Promise<boolean> {
  let loginRes = await login(loginData);
  if (loginRes.status !== 200) {
    throw new Error('ログインに失敗しました');
  }
  const token = loginRes.data.token;
  // 接続許可モデルがサブスクモデル以外であれば購入ページへのログインはさせない
  const result = await isSubscribeUser(token);
  if (!result) {
    return false;
  }

  setUserToken(token);
  await getSubscriptionStatus(token);
  await createCustomer(token);
  let planRes = await getPlan(token);
  if (!planRes.data.success) {
    throw new Error('プランの取得に失敗しました');
  }
  const planData = planRes.data.data || null;
  setUserPlan(planData ? PLANS[planData.userType] : '');
  return true;
}

export async function resetPasswordService(email: string) {
  return await resetPassword(email);
}

export async function getUserService() {
  const token = getUserToken();
  let res = await getUser(token);
  // 過去数字でデータを持っている人が一部いるのでその場合は更新する
  const { prefecture } = res.data;
  if (typeof prefecture === 'number' && prefecture <= 46 && prefecture >= 0) {
    const prefectureStr = provinceCode[prefecture];
    await updateUser(token, {
      ...res.data,
      prefecture: prefectureStr,
    });
  }
  // ユーザ情報を取得したらSentryにfamilyIdを設定する
  Sentry.configureScope((scope) => {
    scope.setUser({ familyId: res.data.family_id })
  })
  return res.data;
}

export async function getOrderHistoryService() {
  const token = getUserToken();
  const orderRes = await getOrderHistory(token);
  // 購入ページ経由で注文されたデータのみに絞る
  const filteredData: OrderData[] = [];
  orderRes.data.forEach((data) => {
    // 購入ページ経由で注文された場合、note項目にfamilyIdやuserIdが文字列として設定されている
    if (data.note) {
      filteredData.push(data);
    }
  })
  orderRes.data = filteredData
  return orderRes
}

export async function cancelOrderService(order: OrderData) {
  const token = getUserToken();
  const { note } = order;
  let userId = Number(note.split(',')?.[1]) || null;
  let invoiceId = note.split(',')?.[3] || null;
  // 一括購入プランローンチに合わせて自動課金開始を止めるため0固定
  return await cancelOrder(token, order.id, 0, invoiceId, userId);
}

export async function isCancellableOrderService(
  order: OrderData
): Promise<boolean> {
  const token = getUserToken();
  return await isCancellable(token, order);
}

export async function getInventoryQuantityService(storefrontId: string) {
  const token = getUserToken();
  return await getInventoryQuantity(token, storefrontId);
}

export async function getCardInfoService(): Promise<ShowCCData | null> {
  const token = getUserToken();
  let cardRes = await getCardInfo(token);
  let planRes = await getPlan(token);
  if (!cardRes.data.success || !planRes.data.success) return null;
  return {
    brand: planRes.data.data?.brand || '',
    expire: cardRes.data.data?.expire || '',
    cardId: cardRes.data.data?.cardId || '',
    last4: cardRes.data.data?.last4 || '',
  };
}

export async function createOrderService(
  cart: CartItem[],
  stripeTokenId: string | undefined
) {
  const token = getUserToken();
  const userRes = await getUser(token);
  const { email, id } = userRes.data;
  const consumables = cart
    .map(i => {
      const id = storefrontIdToStripeId(i.storefrontId);
      if (id)
        return {
          stripeId: id,
          quantity: i.quantity,
        };
    });

  let postData = {
    totalTax: calcTotalTax(cart),
    shippingPrice: calcShippingPrice(cart),
    shopify: cart.map(i => {
      return {
        storefrontId: window.btoa(i.storefrontId),
        quantity: i.quantity,
      };
    }),
    toletta: {
      consumables,
      email,
      userId: id,
      tokenId: stripeTokenId || null,
      // 一括購入プランローンチに合わせて自動課金開始を止めるため0固定
      orderNumberOfSubscriptionToletta: 0,
    },
  };

  return await createOrder(token, postData);
}

export async function getAcceptsMarketingService() {
  const token = getUserToken();
  if (!token) return true;
  let res = await getCustomer(token);
  return getAcceptsMarketing(res.data);
}

export async function changeAcceptsMarketingService() {
  const token = getUserToken();
  let res = await changeAcceptsMarketing(token);
  return getAcceptsMarketing(res.data);
}

function getAcceptsMarketing(customer: CustomerData) {
  if (!customer.email_marketing_consent) {
    return false;
  }
  // 購読状態を返却する
  return customer.email_marketing_consent.state === 'subscribed';
}

export async function changePasswordService(
  currentPassword: string,
  newPassword: string
) {
  const token = getUserToken();
  let userRes = await getUser(token);
  let loginRes = await login({
    username: userRes.data.email,
    password: currentPassword,
  });
  if (loginRes.status !== 200)
    throw new Error('現在のパスワードが正しくありません');
  let hashRes = await getHashPassword(token, newPassword);
  let changeRes = await changePassword(
    token,
    userRes.data.id,
    hashRes.data.hashPassword
  );
  return changeRes;
}

export async function updateUserService(userData: UserData): Promise<void> {
  const token = getUserToken();
  let userUpdateRes = await updateUser(token, userData);
  console.log('Update user: ', userUpdateRes);
}

export async function updateCustomerService(): Promise<void> {
  const token = getUserToken();
  let customerUpdateRes = await updateCustomer(token);
  console.log('Update customer: ', customerUpdateRes);
}

export async function changeCCService(
  previousCardId: string,
  stripeTokenId: string
) {
  const token = getUserToken();
  let userRes = await getUser(token);
  let ccRes = await changeCC(token, {
    previousCardId,
    email: userRes.data.email,
    userId: userRes.data.id,
    tokenId: stripeTokenId,
  });
  if (ccRes.status === 200) {
    if (ccRes.data.success) {
      return '';
    } else {
      return ccRes.data.errorMessage;
    }
  } else {
    throw new Error('カード情報の更新に失敗しました');
  }
}

type RegisterResponse = {
  success: boolean;
  messages: string[];
};
export async function registerService(
  body: RegisterInputData,
  couponCode: string
): Promise<RegisterResponse> {
  let res = await register(body);
  console.log('Register: ', res);
  if (res.data.shopifyResult.success && res.data.tolettaResult.success) {
    let token = res.data.tolettaResult.token;
    setUserToken(token);
    // クーポンコードがあれば適用
    if (couponCode) await registerCoupon(token, couponCode);
    await getSubscriptionStatus(token);
    let planRes = await getPlan(token);
    if (!planRes.data.success) {
      throw new Error('プランの取得に失敗しました');
    }
    const planData = planRes.data.data || null;
    setUserPlan(planData ? PLANS[planData.userType] : '');
    return {
      success: true,
      messages: [
        `${
          res.data.shopifyResult?.errors &&
          res.data.shopifyResult?.errors.length > 0
            ? res.data.shopifyResult?.errors?.[0].message
            : ''
        }`,
        `${res.data.tolettaResult?.message}`,
      ],
    };
  } else {
    return {
      success: false,
      messages: [
        `${
          res.data.shopifyResult?.errors &&
          res.data.shopifyResult?.errors.length > 0
            ? res.data.shopifyResult?.errors?.[0].message
            : ''
        }`,
        `${res.data.tolettaResult?.message}`,
      ],
    };
  }
}

export async function createDraftOrderService(
  cart: CartItem[],
  stripeTokenId: string | undefined
) {
  const token = getUserToken();

  const toletta =
    cart.find(i => i.storefrontId === storefrontId.toletta) ||
    cart.find(i => i.storefrontId === storefrontId.tolettaBaseToletta);

  const consumables = cart
    .map(i => {
      const id = storefrontIdToStripeId(i.storefrontId);
      if (id)
        return {
          id,
          orderNumber: i.quantity,
        };
    });

  if (!toletta)
    throw new Error(
      'トレッタが選択されていません。初回の注文ではトレッタが必要です。'
    );

  let body: DraftOrderInputData = {
    consumables,
    orderNumberOfToletta: toletta.quantity,
    lineItems: [
      {
        variantId: `gid://shopify/ProductVariant/${restVariantId.toletta}`,
        quantity: toletta.quantity,
      },
    ],
    stripeTokenId: stripeTokenId || undefined,
  };

  return await createDraftOrder(token, body);
}

export async function completeDraftOrderService(draftOrderId: string) {
  return await completeDraftOrder(draftOrderId);
}

export async function deleteCustomerService() {
  const token = getUserToken();
  return await deleteCustomer(token);
}

export async function remindMeService() {
  const token = getUserToken();
  return remindMe(token);
}

export async function resetPlan() {
  const token = getUserToken();
  let planRes = await getPlan(token);
  if (!planRes.data.success) {
    throw new Error('プランの取得に失敗しました');
  }
  const planData = planRes.data.data || null;
  setUserPlan(planData ? PLANS[planData.userType] : '');
}

export function getCurrentPlan() {
  return getUserPlan();
}

export function getCartTolettaType(cart: CartItem[]) {
  const standardPlanProductId = storefrontId.toletta;
  const tolettaBasePlanProductId = storefrontId.tolettaBaseToletta;
  const catBasePlanProductId = storefrontId.catBaseToletta;

  if (cart.find(item => item.storefrontId === catBasePlanProductId)) {
    return '頭数ベースプラン';
  } else if (
    cart.find(item => item.storefrontId === tolettaBasePlanProductId)
  ) {
    return '台数ベースプラン';
  } else if (cart.find(item => item.storefrontId === standardPlanProductId)) {
    return 'スタンダードプラン';
  } else return '';
}

export const isCorrectTolettaForPlan = async (
  cart: CartItem[]
): Promise<boolean> => {
  const standardPlanProductId = storefrontId.toletta;
  const tolettaBasePlanProductId = storefrontId.tolettaBaseToletta;
  const catBasePlanProductId = storefrontId.catBaseToletta;
  const token = getUserToken();

  // プランを再取得
  const planRes = await getPlan(token);
  const planData = planRes.data.data || null;
  if (!planData) return false;

  // プランをセット
  setUserPlan(PLANS[planData.userType]);
  const userPlan = PLANS[planData.userType];

  // 判定
  if (userPlan.includes('台数')) {
    if (cart.find(item => item.storefrontId !== tolettaBasePlanProductId)) {
      return false;
    }
    return true;
  } else if (userPlan.includes('スタンダード')) {
    if (cart.find(item => item.storefrontId !== standardPlanProductId)) {
      return false;
    }
    return true;
  } else {
    if (cart.find(item => item.storefrontId !== catBasePlanProductId)) {
      return false;
    }
    return true;
  }
};

export const checkMe = async () => {
  const token = getUserToken();
  if (!token) return;
  await getUser(token);
};

export const checkCouponStatus = async (couponCode: string) => {
  const res = await getCouponStatus(couponCode);
  return res;
};

/**
 * スラッシュ区切りのカード有効期限を返却します
 * @param {string} expire 
 * @return {string} スラッシュ区切りのカード有効期限
 */
export const getCardExpire = (expire: string) => {
  if (!expire) {
    return ''
  } else if (expire.length === 3) {
    return `${expire.slice(0, 1)}/${expire.slice(1, 3)}`;
  } else {
    return `${expire.slice(0, 2)}/${expire.slice(2, 4)}`;
  }
};
