import axios from 'axios';
import { toRest } from '../assets/products';
import {
  ChangeCCRequestData,
  CreateOrderInput,
  CreateOrderResponse,
  CustomerData,
  InventoryQuantityData,
  OrderData,
  SubscriptionPlanData,
  SubscriptionServerResponseData,
  TolettaResponse,
  UserCardData,
} from '../types';

type APIProps = {
  token: string;
  isDebugMode?: boolean;
};

const API = ({ token, isDebugMode = false }: APIProps) =>
  axios.create({
    baseURL: isDebugMode
      ? 'http://localhost:3000/'
      : process.env.GATSBY_TOLETTA_WEB_API,
    headers: {
      Accept: '*/*',
      'Access-Control-Allow-Origin': '*',
      Authorization: `Bearer ${token}`,
    },
  });

export async function getSubscriptionStatus(token: string): Promise<void> {
  let res = await API({ token }).get('toletta/subscription/status');
  console.log(res);
}

export async function getPlan(
  token: string
): Promise<
  TolettaResponse<SubscriptionServerResponseData<SubscriptionPlanData>>
> {
  return await API({ token }).get('toletta/s-plan');
}

export async function createCustomer(token: string): Promise<void> {
  let res = await API({ token }).post('shop/customers');
  console.log(res);
}

export async function getOrderHistory(
  token: string
): Promise<TolettaResponse<OrderData[]>> {
  return await API({ token }).get('shop/orders');
}

export async function cancelOrder(
  token: string,
  orderId: string,
  cancelNumberOfSubscriptionToletta: number,
  invoiceId: string | null,
  userId: number | null
) {
  if (!userId) throw new Error('ユーザIDが見つかりません');
  return await API({ token }).put('toletta/order/cancel', {
    shopify: {
      orderId,
    },
    toletta: {
      cancelNumberOfSubscriptionToletta,
      invoiceId,
      userId,
    },
  });
}

export async function isCancellable(token: string, order: OrderData) {
  // スプシにすでに出力済み出ないか確認する
  const res = await API({ token }).get(
    `toletta/gss?name=${order.name.replace('#', '')}`
  );
  let matchedOrders = res?.data?.data;
  // マッチするオーダがあった場合
  return !(matchedOrders?.length > 0);
}

export async function getInventoryQuantity(
  token: string,
  storefrontId: string
): Promise<TolettaResponse<InventoryQuantityData>> {
  return await API({ token }).post('shop/variant', {
    // GraphQLからのレスポンスはbase64デコードされているため、false固定
    variantId: toRest(storefrontId, false),
  });
}

export async function getCardInfo(
  token: string
): Promise<TolettaResponse<SubscriptionServerResponseData<UserCardData>>> {
  return await API({ token }).get('toletta/card');
}

export async function createOrder(
  token: string,
  postData: CreateOrderInput
): Promise<TolettaResponse<CreateOrderResponse>> {
  return await API({ token }).post('toletta/order', postData);
}

export async function getCustomer(
  token: string
): Promise<TolettaResponse<CustomerData>> {
  return await API({ token }).get('shop/customer');
}

export async function changeAcceptsMarketing(
  token: string
): Promise<TolettaResponse<CustomerData>> {
  return await API({ token }).put('shop/customer/am');
}
export async function getHashPassword(
  token: string,
  password: string
): Promise<TolettaResponse<{ hashPassword: string }>> {
  return await API({ token }).post('toletta/hashpassword', { password });
}

export async function updateCustomer(
  token: string
): Promise<TolettaResponse<any>> {
  return await API({ token }).put('shop/customers', { data: 'update' });
}

export async function changeCC(
  token: string,
  data: ChangeCCRequestData
): Promise<TolettaResponse<SubscriptionServerResponseData<undefined>>> {
  return await API({ token }).put('toletta/card', data);
}
