export const PLANS = [
  '新規',
  '既存',
  '既存（買い切り）',
  '既存（サブスク）',
  'フリーベーシック',
  'フリープレミアム',
  'ウェルニャス',
  '新基本プラン',
  '台数ベースプラン',
  '台数ベースフリー',
  '台数ベースウェルニャス',
  'スタンダードプラン',
  'スタンダードフリープラン',
  'スタンダードウェルニャスプラン',
];
