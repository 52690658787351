import axios, { AxiosResponse } from 'axios';
import {
  DeleteCustomerResponseData,
  DraftOrderInputData,
  DraftOrderResponseData,
  GetCouponResponseData,
  RegisterInputData,
  RegisterResponseData,
  TolettaResponse,
} from '../types';

type APIProps = {
  token?: string;
  isDebugMode?: boolean;
};

const API = ({ token = '', isDebugMode = false }: APIProps) =>
  axios.create({
    baseURL: isDebugMode
      ? 'http://localhost:4001/dev/'
      : process.env.GATSBY_TOLETTA_SHOPIFY_API,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export async function registerCoupon(
  token: string,
  couponCode: string
): Promise<AxiosResponse<any>> {
  return await API({ token }).post(`api/v1/coupons/register`, {
    couponCode,
  });
}

export async function getCouponStatus(
  couponCode: string
): Promise<AxiosResponse<GetCouponResponseData>> {
  return await API({}).get(`api/v1/coupons/status/?couponCode=${couponCode}`);
}

export async function register(
  body: RegisterInputData
): Promise<TolettaResponse<RegisterResponseData>> {
  return await API({}).post('api/v1/register', body);
}

export async function createDraftOrder(
  token: string,
  body: DraftOrderInputData
): Promise<TolettaResponse<DraftOrderResponseData>> {
  return await API({ token }).post('api/v1/draft-order', body);
}

export async function completeDraftOrder(
  draftOrderId: string
): Promise<TolettaResponse<DraftOrderResponseData>> {
  return await API({}).post('api/v1/complete-draft-order', {
    draftOrderId,
  });
}

export async function deleteCustomer(
  token: string
): Promise<TolettaResponse<DeleteCustomerResponseData>> {
  return await API({ token }).delete('api/v1/customer');
}

export async function remindMe(
  token: string
): Promise<TolettaResponse<{ success: boolean; result: string[] }>> {
  return API({ token }).get('api/v1/draft-order-remind-me');
}
